import React from "react"
import Link from "gatsby-link"
import get from "lodash/get"
import Helmet from "react-helmet"
import {graphql} from "gatsby"

import {rhythm} from "../../utils/typography"
import {formatDate, langFromPath} from "../../i18n-config"
import Layout from "../../components/layout"

class BlogIndex extends React.Component {
  render() {
    const siteTitle = get(this, "props.data.site.siteMetadata.title")
    const posts = get(this, "props.data.allMarkdownRemark.edges")
    const { location } = this.props
    return (
      <Layout location={location} title={siteTitle}>
        <Helmet title={siteTitle}>
          <meta
            name="description"
            content="Jeux développés par « A Game Studio ». A Time Paradox: sortirez-vous de la boucle temporelle sans créer de paradoxes ?"
          />
        </Helmet>
        <h1>Projets</h1>
        {posts.map(({ node }) => {
          const title = get(node, "frontmatter.title") || node.fields.slug
          return (
            <div key={node.fields.slug}>
              <h3
                style={{
                  marginBottom: rhythm(1 / 4),
                }}
              >
                <Link style={{ boxShadow: "none" }} to={node.fields.slug}>
                  {title}
                </Link>
              </h3>
              <small>
                {formatDate(
                  node.frontmatter.date,
                  langFromPath(location.pathname)
                )}
              </small>
              <p dangerouslySetInnerHTML={{ __html: node.excerpt }} />
            </div>
          )
        })}
      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query ProjectsQueryFR {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { fields: { slug: { glob: "/fr/projects/**" } } }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date
            title
          }
        }
      }
    }
  }
`
